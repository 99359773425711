/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Grid, Link, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import BlueCardInformations from 'components/BlueCardInformations';
import { CustomButton } from 'components/CustomButton';
import { FixedFooter } from 'components/FixedFooter';
import Scaffold from 'components/Scaffold';
import { usePollingEffect } from 'hooks/usePollingEffect';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CLIENT_ROUTE } from 'routes/consts';
import PaymentService from 'services/paymentService';
import { useAppStore } from 'store';
import { SAS_PLATFORM } from 'texts';
import { BILL_STATUS, PAYMENT_METHODS, PAYMENT_STATUS } from 'types/enums';
import { Payment, SUBSCRIPTION_STATUS } from 'types/payment';
import { RouteParams } from 'types/routeParams';
import { formatDate } from 'utils';
import { shallow } from 'zustand/shallow';
import CardPaymentCreditCard from '../MarketplaceOrderPayments/components/CardPaymentCreditCard';
import LoadingPaymentFeedback from '../MarketplaceOrderPayments/components/LoadingPaymentFeedback';
import PaymentBankslip from '../MarketplaceOrderPayments/components/PaymentBankslip';
import PaymentCash from '../MarketplaceOrderPayments/components/PaymentCash';
import PaymentPix from '../MarketplaceOrderPayments/components/PaymentPix';
import { useStyles } from './styles';

const AwaitingPayment: React.FC = () => {
  const { id: paymentId } = useParams<RouteParams>();
  const [payment, setPayment] = useState<Payment>();
  const { showAlert, configurations } = useAppStore(
    state => ({
      showAlert: state.alert.showAlert,
      configurations: state.configurations.data,
    }),
    shallow,
  );
  const history = useHistory();
  const styles = useStyles();

  const isPaymentFinishedProcessing = useMemo(() => {
    if (!payment) return false;

    const subscriptionsCount = payment?.subscriptions?.length ?? 0;
    const billsCount = payment?.bills?.length ?? 0;

    // Essa gambiarra se fez necessário por motivo do pagamento poder ter mais de uma assinatura
    const hasOneBillFinishedProcessing =
      billsCount >= subscriptionsCount
        ? payment?.bills?.some(({ status }) =>
            [
              BILL_STATUS.BILLED,
              BILL_STATUS.REJECTED,
              BILL_STATUS.CANCELED,
              BILL_STATUS.AWAITING_PAYMENT,
            ].includes(status),
          )
        : false;

    const isErroredPayment = payment?.status === PAYMENT_STATUS.ERROR;
    const isFinishedPayment =
      hasOneBillFinishedProcessing &&
      [PAYMENT_STATUS.CONCLUDED, PAYMENT_STATUS.PROCESSING].includes(
        payment.status,
      );
    const isFuturePayment =
      !payment?.bills.length && payment.status === PAYMENT_STATUS.FUTURE;

    return isFinishedPayment || isFuturePayment || isErroredPayment;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment?.bills]);

  const handleGoBack = () => {
    history.goBack();
  };

  const handleGoToPaymentDetails = () => {
    if (!payment?.id) return;
    history.push(
      CLIENT_ROUTE.FINANCIAL_DETAILS.replace(':id', String(payment.id)),
    );
  };

  usePollingEffect(
    async () => {
      try {
        // FIXME: Buscar apenas os campos necessários
        const response = await PaymentService.payment(paymentId, {
          join: [
            ['financialGuardian'],
            ['financialGuardian.user'],
            ['bills'],
            ['dependent'],
            {
              field: 'subscriptions',
              select: ['id', 'status', 'externalId'],
            },
          ],
        });
        setPayment(response.data);

        const paymentStatus = response?.data?.status;
        const isFuturePayment =
          !response?.data?.bills.length &&
          paymentStatus === PAYMENT_STATUS.FUTURE;
        const isErroredPayment = paymentStatus === PAYMENT_STATUS.ERROR;

        return {
          mustStop: isErroredPayment || isFuturePayment,
        };
      } catch (error: any) {
        showAlert({
          message: error?.message || 'Erro ao carregar pagamento',
          severity: 'error',
        });
      }
    },
    [paymentId],
    {
      // Fetch payment every 5 seconds, for 5 minutes
      attemptsLimit: 60,
      interval: 5_000,
    },
  );

  const handleReloadPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleReloadPage();
    }, 30_000);

    return () => clearTimeout(timer);
  }, []);

  const handleOpenSAS = () => {
    window.open(SAS_PLATFORM, 'name', 'width=800,height=600');
  };

  const renderLoadingPayment = () => {
    if (isPaymentFinishedProcessing) return null;

    return (
      <Box
        justifyContent="center"
        alignItems="center"
        display="flex"
        mb={4}
        flexDirection="column"
      >
        <LoadingPaymentFeedback />

        <Typography>
          A página será atualizada em 30 segundos, caso não atualize favor
          <Link onClick={handleReloadPage} className={styles.linkOriginal}>
            {' '}
            clique aqui.{' '}
          </Link>
        </Typography>
      </Box>
    );
  };

  const renderPayment = () => {
    if (!payment?.bills?.length) return null;

    return (
      <Grid container spacing={4}>
        {payment.bills?.map(billItem => {
          switch (billItem.paymentMethodCode) {
            case PAYMENT_METHODS.PIX:
              return (
                <PaymentPix
                  key={billItem.id}
                  payment={payment}
                  bill={billItem}
                />
              );
            case PAYMENT_METHODS.BANK_SLIP:
              return (
                <PaymentBankslip
                  key={billItem.id}
                  payment={payment}
                  bill={billItem}
                />
              );
            case PAYMENT_METHODS.CARD:
              return (
                <CardPaymentCreditCard
                  key={billItem.id}
                  payment={payment}
                  bill={billItem}
                />
              );
            case PAYMENT_METHODS.CASH:
              return (
                <PaymentCash
                  key={billItem.id}
                  payment={payment}
                  bill={billItem}
                />
              );
            default:
              return <p>{payment.paymentMethodCode}</p>;
          }
        })}
      </Grid>
    );
  };

  const renderAlertPaymentError = () => {
    const hasError =
      payment?.status === PAYMENT_STATUS.ERROR ||
      payment?.subscriptions?.some(
        ({ status }) => status === SUBSCRIPTION_STATUS.FAILED,
      );

    if (!hasError) return null;

    return (
      <Box display="flex" mb={2} justifyContent="center" alignItems="center">
        <Alert variant="filled" severity="error">
          O pagamento não foi realizado devido a um{' '}
          <b>erro no processo de cobrança</b>. Não se preocupe, caso alguma
          cobrança seja realizada, a mesma será automaticamente estornada em
          alguns instantes. <b>Realize o processo de contratação novamente!</b>
        </Alert>
      </Box>
    );
  };

  const renderAlertPaymentScheduled = () => {
    const isFuturePayment =
      !payment?.bills?.length && payment?.status === PAYMENT_STATUS.FUTURE;
    if (!isFuturePayment) return null;

    return (
      <Box display="flex" mb={2} justifyContent="center" alignItems="center">
        <Alert variant="filled" severity="success">
          Seu pagamento foi{' '}
          <b>
            progamado para o dia{' '}
            {formatDate({
              date: payment!.paymentStartDate,
            })}
          </b>
          . Avisaremos por email assim que emitirmos a sua fatura.
        </Alert>
      </Box>
    );
  };

  return (
    <Scaffold
      rawTitle={
        <>
          Atualização do status <b>do pagamento</b>
        </>
      }
      rawSubtitle="Acompanhe aqui o status da primeira parcela do seu pagamento."
    >
      <BlueCardInformations
        title="FALTA POUCO"
        descriptionText={
          <>
            Estamos processando a sua solicitação.{' '}
            <strong>Assim que o pagamento for confirmado ou agendado,</strong> o
            processo de contratação será concluído. Para acessar a loja de
            materiais escolares, acesse o SAS{' '}
            <Link onClick={handleOpenSAS} className={styles.link}>
              clicando aqui
            </Link>
            . Lembre-se de utilizar a sua chave de acesso:{' '}
            <strong>{configurations?.storeKey || ''}</strong>
          </>
        }
      />
      <Box mt={4} />
      {renderLoadingPayment()}
      {renderAlertPaymentError()}
      {renderAlertPaymentScheduled()}
      {renderPayment()}
      <FixedFooter>
        {!!payment?.id && (
          <CustomButton variant="secondary" onClick={handleGoToPaymentDetails}>
            Ver detalhes do pagamento
          </CustomButton>
        )}

        <CustomButton variant="secondary" onClick={handleGoBack}>
          Ir para matrículas
        </CustomButton>
      </FixedFooter>
    </Scaffold>
  );
};

export default AwaitingPayment;
